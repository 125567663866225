@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@100..900&display=swap');

body {
  background-color: #white;
  color: #2A2B2A;
  font-family: "M Plus 1", sans-serif;
  font-size: medium;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table-container {
  max-width: 100%;
  overflow-x: auto;
}
